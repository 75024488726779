<template>
    <div class="background-container">
<div class="stars"></div>
<div class="twinkling"></div>
</div>
<head>
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="description"
          content=""/>

    <!--Inter UI font-->
    <link href="https://rsms.me/inter/inter-ui.css" rel="stylesheet">

    <!--vendors styles-->
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css">

    <!-- Bootstrap CSS / Color Scheme -->
    <link rel="stylesheet" href="css/default.css" id="theme-color">
</head>
<body class="zzz" style="position: relative; z-index: 100">
<!--navigation-->
<section class="smart-scroll">
    
    <div class="container-fluid">
        <nav class="navbar navbar-expand-md navbar-dark">
            <a class="navbar-brand heading-black">
                GalaxyFH
            </a>
            <button class="navbar-toggler navbar-toggler-right border-0" type="button" data-toggle="collapse"
                    data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                    aria-label="Toggle navigation">
                <span data-feather="grid"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a class="nav-link page-scroll" href="#features">Features</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link page-scroll" href="#pricing">Pricing</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link page-scroll" href="#faq">FAQ</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</section>
<div class="card bg-transparent">
<!--hero header-->
<section class="py-7 py-md-0 bg-hero" id="home">
    <div class="container">
        <div class="row vh-md-100">
            <div class="col-md-8 col-sm-10 col-12 mx-auto my-auto text-center">
                <h1 class="heading-white text-capitalize shadowtext" style="color: orangered; font-size: 350%">GalaxyFH</h1>
            </div>
            </div>
    </div>
</section>
<br>
<br>
<!-- features section -->
<section class="pt-6 pb-7" id="features">
    <div class="container">
        <div class="row">
            <div class="col-md-6 mx-auto text-center">
                <h2 class="heading-black text-light">Features</h2>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-10 mx-auto">
                <div class="row feature-boxes">
                    <div class="col-md-6 box">
                        <div class="icon-box box-primary">
                            <div class="icon-box-inner">
                                <span data-feather="edit-3" width="35" height="35"></span>
                            </div>
                        </div>
                        <h5 class="text-light">Create once. View everywhere.</h5>
                        <p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum in
                            nisi commodo, tempus odio a, vestibulum nibh.</p>
                    </div>
                    <div class="col-md-6 box">
                        <div class="icon-box box-success">
                            <div class="icon-box-inner">
                                <span data-feather="monitor" width="35" height="35"></span>
                            </div>
                        </div>
                        <h5 class="text-light">Customized TV View</h5>
                        <p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum in
                            nisi commodo, tempus odio a, vestibulum nibh.</p>
                    </div>
                    <div class="col-md-6 box">
                        <div class="icon-box box-danger">
                            <div class="icon-box-inner">
                                <span data-feather="layout" width="35" height="35"></span>
                            </div>
                        </div>
                        <h5 class="text-light">Integrated Casket Inventory System</h5>
                        <p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum in
                            nisi commodo, tempus odio a, vestibulum nibh.</p>
                    </div>
                    <div class="col-md-6 box">
                        <div class="icon-box box-info">
                            <div class="icon-box-inner">
                                <span data-feather="globe" width="35" height="35"></span>
                            </div>
                        </div>
                        <h5 class="text-light">Multiple Accounts</h5>
                        <p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum in
                            nisi commodo, tempus odio a, vestibulum nibh.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<br>
<br>
<!--pricing section-->
<section class="py-7 section-angle top-right bottom-right" id="pricing">
    <div class="container">
        <div class="row">
            <div class="col-md-6 mx-auto text-center">
                <h2 class="text-white heading-black">Pricing</h2>
                <p class="text-light lead">Simple Pricing. A one time set up cost and a small monthly charge.</p>
            </div>
        </div>
        <!--pricing tables-->
        <div class="row pt-5 pricing-table">
            <div class="col-12 mx-auto">
                <div class="card-deck pricing-table">
                    <div class="card bg-dark">
                        <div class="card-body">
                            <h3 class="card-title text-light pt-3">Unlimited Use</h3>
                            <h2 class="card-title  mb-0 pt-4" style="color: orangered">$1500</h2>
                            <div class="text-light font-weight-medium mt-2">One-time Set Up Cost</div>
                            <h2 class="card-title  mb-0 pt-4" style="color: orangered">$25</h2>
                            <div class="text-light font-weight-medium mt-2">per month</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            
    </div>
</section>
<br>
<br>
<br>
<!--faq section-->
<section class="py-7" id="faq">
    <div class="container">
        <div class="row">
            <div class="col-md-6 mx-auto text-center">
                <h2 class="text-light">Frequently Asked Questions</h2>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-10 mx-auto">
                <div class="row">
                    <div class="col-md-6 mb-5">
                        <h6 class="text-light">Can I try it for free?</h6>
                        <p class="text-muted">Nam liber tempor cum soluta nobis eleifend option congue nihil imper per tem por legere me doming.</p>
                    </div>
                    <div class="col-md-6 mb-5">
                        <h6 class="text-light">Do you have hidden fees?</h6>
                        <p class="text-muted">Nam liber tempor cum soluta nobis eleifend option congue nihil imper per tem por legere me doming.</p>
                    </div>
                    <div class="col-md-6 mb-5">
                        <h6 class="text-light">What are the payment methods you accept?</h6>
                        <p class="text-muted">Nam liber tempor cum soluta nobis eleifend option congue nihil imper per tem por legere me doming.</p>
                    </div>
                    <div class="col-md-6 mb-5">
                        <h6 class="text-light">How often do you release updates?</h6>
                        <p class="text-muted">Nam liber tempor cum soluta nobis eleifend option congue nihil imper per tem por legere me doming.</p>
                    </div>
                    <div class="col-md-6 mb-5">
                        <h6 class="text-light">What is your refund policy?</h6>
                        <p class="text-muted">Nam liber tempor cum soluta nobis eleifend option congue nihil imper per tem por legere me doming.</p>
                    </div>
                    <div class="col-md-6 mb-5">
                        <h6 class="text-light">How can I contact you?</h6>
                        <p class="text-muted">Nam liber tempor cum soluta nobis eleifend option congue nihil imper per tem por legere me doming.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-6 mx-auto text-center">
                <h5 class="mb-4 text-light">Have questions?</h5>
                <a href="#" class="btn btn-primary">Contact us</a>
            </div>
        </div>
        <br>
        <br>
    </div>
</section>

<!--scroll to top-->
<div class="scroll-top">
    <i class="fa fa-angle-up" aria-hidden="true"></i>
</div>
</div>
</body>
</template>
<script>
//document.body.style.backgroundColor = "black";
</script>

<style>

.background-container{
	position: fixed;
	top: 0;
	left:0;
	bottom: 0;
	right: 0;
}

.stars {
 background: black url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/stars.png) repeat;
 position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: block;
	z-index: -1;
}

.twinkling{
	width:10000px;
	height: 100%;
	background: transparent url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/twinkling.png") repeat;
	background-size: 1000px 1000px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
	
}
img{
  height: 70vh;
  width:70vh;
  position: absolute;
  z-index: 3;
  right: 20px;
}
    .shadowtext {
        text-shadow: 0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000;
    }
</style>